.closer-look-modal-placement {
    width: calc(40vw);
    margin-left: calc(5vw);
    height: calc(60vh);
    position: absolute;
    margin-top: calc(10vh);
}

.closer-look-modal-placement-mobile {
    width: calc(95vw);
    margin-left: calc(5vw);
    margin: 0 auto;
    overflow: hidden;
    margin-top: calc(7vh);
    height: calc(82vh);
    border: 1px solid #AAA;
    border-radius: 4px;
}

.closer-look {
    width: 100%;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid #AAA;
    height: 100%;
    padding: 32px;
}

.closer-look-mobile {
    width: 95%;
    overflow-y: scroll;
    background-color: white;
    height: 100%;
    padding: 10px;
}

.badge {
    float: left;
    width: 2vw;
    height: 2vw;
    min-width: 45px;
    min-height: 45px;
}

.press-dialog {
    width: calc(40vw);
    height: 60vh;
    overflow-y: scroll;
    position: absolute;
    top: calc(14vh);
    left: 52vw;
    text-align: left;
    z-index: 1000;
    padding: 15px;

}

.close-press-button {
    background-color: #4285f4 !important; 
    color: #fff !important;
    float: right;
    border-radius: 100%;  
}

.press-image img {
    overflow: hidden;
}
.MuiImageListItemBar-titleWrap {
    padding: 8px;
}

.biblio {
    font-size: 12px;
}

.village-header {
    opacity: 0.375;
    font-variant: all-small-caps;
    margin-bottom: -0.375em;
}

.village-header a {
    text-decoration: none;
}
