@font-face {
    font-family: 'workaholic' ;
    src: url('../../workaholic.ttf') format('truetype');
}

@font-face {
    font-family: 'YanoneTagesschrift' ;
    src: url('../../YanoneTagesschrift.ttf') format('truetype');
}

@font-face {
    font-family: 'ChopinScript' ;
    src: url('../../ChopinScript.otf') format('opentype');
}

@font-face {
    font-family: 'Boston1851Italic' ;
    src: url('../../Boston1851ItalicCondensed.ttf') format('truetype');
}

@font-face {
    font-family: 'Boston1851ItalicBold' ;
    src: url('../../Boston1851ItalicBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Boston1851Bold' ;
    src: url('../../Boston1851Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'ButlerBold' ;
    src: url('../../Butler_Bold.otf') format('opentype');
}

@font-face {
    font-family: 'CashCurrency' ;
    src: url('../../CashCurrency.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDisplay_Condensed-BoldItalic' ;
    src: url('../../NotoSerifDisplay_Condensed-SemiBoldItalic.ttf') format('truetype')
}

.map {
    width:100%;
    height: calc(100vh - 48px);
    margin-top: 48px
}

.map-container {
    height: 100%;
    width: 100%;
    /* z-index: -1; */
}


.leaflet-tooltip {
    background: none;
    border: none;
    box-shadow: none;
    
}

.landmark-1870 {
    font-family: 'Boston1851Italic', Times, serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #574d42 ;
}

.landmark-1830 {
    font-family: 'NotoSerifDisplay_Condensed-BoldItalic', Times, serif;
    font-size: 16px;
    color: #675e54 ;
}

.landmark-1994 {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 600;
    filter: blur(0.5px);
    letter-spacing: 0px;
    color: #c53b38;
    text-shadow: 1px 1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, -1px -1px 0 #FFFFFF, 1px 0px 0 #FFFFFF, 0px 1px 0 #FFFFFF, -1px 0px 0 #FFFFFF, 0px -1px 0 #FFFFFF;
}

.landmark-1957 {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #000 ;
}

.landmark-now {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: #574d42;
    font-style: italic;
    font-weight: 600;
    text-shadow: 1px 1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, -1px -1px 0 #FFFFFF, 1px 0px 0 #FFFFFF, 0px 1px 0 #FFFFFF, -1px 0px 0 #FFFFFF, 0px -1px 0 #FFFFFF;
}

.landmark:hover {
    text-shadow: 1px 1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, -1px -1px 0 #FFFFFF, 1px 0px 0 #FFFFFF, 0px 1px 0 #FFFFFF, -1px 0px 0 #FFFFFF, 0px -1px 0 #FFFFFF;
}

.leaflet-tooltip-top {
    padding: 0px;
    margin-top: 0px;
}

.leaflet-tooltip-top:before {
    border-top-color: transparent;
}
.leaflet-tooltip-right:before {
    border-right-color: transparent;
}

.place {
    font-size:24px;
    font-variant: small-caps;
    font-family: 'workaholic', Times, serif;
    margin-top: 2vw;
    margin-left: 2vw;
    text-shadow:0px 1px 0 rgb(245, 228, 208),0px -1px 0 rgb(245, 228, 208),1px 0px 0 rgb(245, 228, 208),-1px 0px 0 rgb(245, 228, 208),1px 1px 0 rgb(245, 228, 208),1px -1px 0 rgb(245, 228, 208),-1px 1px 0 rgb(245, 228, 208),-1px -1px 0 rgb(245, 228, 208);
}

.cemetery-1957 {
    font-size: 12px;
    text-transform: uppercase;
    color: '#333';
    font-family: 'Arial Narrow', sans-serif;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #000 ;
}

.cemetery-1870 {
    font-size: 12px;
    color: '#96946';
    font-family: 'Boston1851', Times, serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #574d42 ;
}

.cemetery-1830 {
    font-size: 12px;
    text-transform: uppercase;
    color: '#96946';
    font-family: 'Boston1851', Times, serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #574d42 ;
}

.land-grant {
    font-size: 20px;
    font-family: 'ChopinScript';
    line-height: 1em;
}
.photo-icon {
    
    background-color: none;
    opacity: 0.9;
    transition: all 300ms ease-in-out;
}

.photo-icon:hover {
    transition: all 300ms ease-in-out;
    
    background-color: #FFF;
    -webkit-box-shadow: 1px 1px 10px 3px #FFFFFF; 
    box-shadow: 1px 1px 10px 3px #FFFFFF;
    
}

.run-story {
    position:absolute !important;
    top: 65px;
    right: 160px;
    z-index: 1000;
}

.cemetery-1870 {
    font-size: 12px;
    text-transform: uppercase;
    color: '#96946';
}
.cemetery-1957 {
    font-size: 12px;
    text-transform: uppercase;
    color: '#96946';
}

.place-1830 {
    font-size: 24px;
    font-variant: small-caps;
    font-family: 'CashCurrency', Times, serif;
    letter-spacing: 0px;
    margin-top: 2vw;
    margin-left: 2vw;
    color: #8c7a69;
    text-shadow:0px 1px 0 rgb(245, 228, 208),0px -1px 0 rgb(245, 228, 208),1px 0px 0 rgb(245, 228, 208),-1px 0px 0 rgb(245, 228, 208),1px 1px 0 rgb(245, 228, 208),1px -1px 0 rgb(245, 228, 208),-1px 1px 0 rgb(245, 228, 208),-1px -1px 0 rgb(245, 228, 208);
}

.place-1830-clickable {
    font-size: 24px;
    font-variant: small-caps;
    font-family: 'CashCurrency', Times, serif;
    letter-spacing: 0px;
    margin-top: 2vw;
    margin-left: 2vw;
    color: #8c7a69;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #022cff;
    text-shadow:0px 1px 0 rgb(245, 228, 208),0px -1px 0 rgb(245, 228, 208),1px 0px 0 rgb(245, 228, 208),-1px 0px 0 rgb(245, 228, 208),1px 1px 0 rgb(245, 228, 208),1px -1px 0 rgb(245, 228, 208),-1px 1px 0 rgb(245, 228, 208),-1px -1px 0 rgb(245, 228, 208);
}

.place-1870 {
    font-size: 24px;
    font-variant: small-caps;
    font-family: 'Boston1851Bold', Times, serif;
    letter-spacing: -2px;
    margin-top: 2vw;
    margin-left: 2vw;
    color: #8c7a69;
    text-shadow:0px 1px 0 rgb(245, 228, 208),0px -1px 0 rgb(245, 228, 208),1px 0px 0 rgb(245, 228, 208),-1px 0px 0 rgb(245, 228, 208),1px 1px 0 rgb(245, 228, 208),1px -1px 0 rgb(245, 228, 208),-1px 1px 0 rgb(245, 228, 208),-1px -1px 0 rgb(245, 228, 208);
}

.place-1870-clickable {
    font-size: 24px;
    font-variant: small-caps;
    font-family: 'Boston1851Bold', Times, serif;
    letter-spacing: -2px;
    margin-top: 2vw;
    margin-left: 2vw;
    color: #8c7a69;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #022cff;
    text-shadow:0px 1px 0 rgb(245, 228, 208),0px -1px 0 rgb(245, 228, 208),1px 0px 0 rgb(245, 228, 208),-1px 0px 0 rgb(245, 228, 208),1px 1px 0 rgb(245, 228, 208),1px -1px 0 rgb(245, 228, 208),-1px 1px 0 rgb(245, 228, 208),-1px -1px 0 rgb(245, 228, 208);
}

.place-1957 {
    font-size: 24px;
    font-family: 'Butlerbold', Times, serif;
    letter-spacing: -1px;
    margin-top: 2vw;
    margin-left: 2vw;
    text-shadow:0px 1px 0 white,0px -1px 0 white,1px 0px 0 white,-1px 0px 0 white,1px 1px 0 white,1px -1px 0 white,-1px 1px 0 white,-1px -1px 0 white;
}

.place-1957-clickable {
    font-size: 24px;
    font-family: 'Butlerbold', Times, serif;
    letter-spacing: -1px;
    margin-top: 2vw;
    margin-left: 2vw;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #022cff;
    text-shadow:0px 1px 0 white,0px -1px 0 white,1px 0px 0 white,-1px 0px 0 white,1px 1px 0 white,1px -1px 0 white,-1px 1px 0 white,-1px -1px 0 white;
}

.photo-marker {
    width: 15px;
    height: 15px;
    transform-origin: center center;
    transition: width 0.25s ease-in-out, height 0.25s ease-in-out, opacity 0.25s ease-in-out;
    background-size: cover;
    border: 1px solid #8c7a69;
    border-radius: 5%;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    z-index: 1000;
    opacity: 0.5;
}

.photo-marker:hover {
    width: 100px;
    z-index: 2000;
    height: 100px;
    opacity: 0.75;
}

.leaflet-div-icon { 
    border: none;
}

.ag-sherlock_smoke {
    display: block;
    height: 21.25em;
    width: 16.875em;
    z-index: 1100;
    border: 1px solid #000;
    opacity: 1;
    position: relative;
    
  
    -webkit-transition: opacity 2.4s linear;
    -moz-transition: opacity 2.4s linear;
    -o-transition: opacity 2.4s linear;
    transition: opacity 2.4s linear;
  }
  
  
  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }
  
  }
  
  @media only screen and (max-width: 639px) {
  
  }
  
  @media only screen and (max-width: 479px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }
  
  }
  
  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }
  
  }

  .smoke-marker {
    width: 100px;
    height: 126px;
  }
  