.timeline {
    position: absolute;
    right: 0;
    z-index: 1000;
    top: 64px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding-top: 20px;
    padding-left:20px;
}
.yearpicker-checkbox span {
    font-size: 0.8em
}

.timeline-h {
    width: calc(100vw - 700px);
    position: absolute;
    padding: 50px;
    padding-bottom: 15px;
    padding-top: 15px;
    right: 0;
    z-index: 1200;
    background-color: rgba(255, 255, 255, 0.75);
    bottom: 0;
}

.timeline-h-mobile {
    width: 75%;
    position: absolute;
    left: 0%;
    padding-left: 12.5%;
    padding-right: 12.5%;
    z-index: 1200;
    background-color: rgba(255, 255, 255, 1.0);
    border-top: 1px solid #AAA;
    bottom: 0;
}

.MuiSlider-markLabel {
    font-size: 1em !important;
    font-weight: bold !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.time-period {
    position: absolute;
}
.time-period p {
    margin: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 0.5em;
    color: white;
    text-shadow: -1px 0 #666, 0 1px #666, 1px 0 #666, 0 -1px #666;
}
.war-period {
    position: absolute;
    bottom: 56px;
    opacity: 0.75;
}
.war-period p {
    margin: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 0.5em;
    color: white;
    text-shadow: -1px 0 #666, 0 1px #666, 1px 0 #666, 0 -1px #666;
}
.MuiSlider-thumb {
    z-index: 1200;
}
.MuiSlider-root {
    z-index: 1200;
}
.MuiSlider-thumb {
    border-radius: 3px !important;
    border: #FFF solid 1px;
    background-color: #0099cc !important;
    height: 30px !important;
}
.MuiSlider-markLabel {
    margin-top: 5px;
    font-size: 0.8em !important;
}