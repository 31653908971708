.summary {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid #AAA;
    padding: 32px;
}

.summary-mobile {
    width: 95%;
    overflow-y: scroll;
    background-color: white;
    height: 100%;
    padding: 10px;
}

.MuiImageListItemBar-titleWrap {
    padding: 8px;
}