.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.standard-modal-placement {
  width: calc(40vw);
  margin-left: calc(5vw);
  height: calc(60vh);
  position: absolute;
  margin-top: calc(10vh);
  border-radius: 4px;
}

.mobile-modal-placement {
  width: calc(90vw);
  margin-left: calc(5vw);
  margin: 0 auto;
  overflow: hidden;
  margin-top: calc(7vh);
  height: calc(90vh);
  border: 1px solid #AAA;
  border-radius: 4px;
}

.standard-right-modal-placement {
  width: calc(40vw);
  margin-left: calc(55vw);
  height: calc(60vh);
  position: absolute;
  margin-top: calc(10vh);
}

.standard-photo-caption {
  font-size: 0.75em;
  text-align: center;
  font-style: italic;
  color: #333;
}

.standard-photo {
  /* float: right; */
  width: 30%;
  margin: 10px;
}

.standard-photo img {
  width: 100%;
}

.small-portrait {
  /* float: right; */
  width: 96px;
  margin: 10px;
  margin-top: 0px;
}

.small-portrait img {
  width: 100%;
}

.centered-modal-placement {
  margin: 0 auto;
  overflow: hidden;
}

.close-button {
  background-color: #4285f4 !important; 
  color: #fff !important;
  float: right;
  border-radius: 4px;  
}
.share-button {
  float: right;
}

.biblio {
  font-size: 0.75em;
}

.inline {
  width: 14px;
  margin-bottom: -2px;
}