.landmark-card {
    width: 600px;
    position: absolute;
    bottom: 0px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.75);
    
}
.landmark-container {
    height: 350px;
    overflow-y: scroll;
    width: 565px;
}
.landmark-container.minimized {
    height: 10px;
}
.MuiDataGrid-cellContent {
    font-size: 12px !important;
}