.splash-screen {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 500px;
    height: auto;
}

.splash-screen-mobile {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(95vw);
    height: auto;
}

.splash-logo {
    width: 100%;
    z-index: 100;
    position: absolute;
    top: 100px;
    left: 0px;
    filter: invert()
}

.tutorial-screen {
    padding: 32px;
    overflow-y: hidden;
}

.portrait-video {
    width: 50% !important;
}

.landscape-video {
    width: 100%;
}

.legal {
    font-size: 0.75em;
}

.bottom-button {
    float: right;
}
.about-screen {
    width: 550px;
}

.header-video {
    object-fit: cover;
    height: 200px;
    object-position: 0% 0%;
}

.about {
    width: 100%;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid #AAA;
    height: 100%;
}

.about p {
    font-size: 0.95em;
}

footer {
    width: 100%;
    font-size: 0.75em;
}

footer .left {
    float: left;
}

footer .right {
    float: right;
}

.small-open-new {
    width: 10px !important;
    height: 10px;
}

.small-open-new-icon {
    width: 10px !important;
    height: 10px;
    color: blue;
    margin-top: -5px;
}