.Mui-checked {
    color: white;
}
.top-bar {
    background-color: #3A332A;
    color: white;
}

.MuiToolbar-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.community-menuitem {
    width: 100%
}