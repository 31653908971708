.share-photo-button {
    float: right;
}
.photo-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #000;
    padding: 32px;
}